import React from 'react';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import useForm from '../hooks/useForm';
import { SelectPresenceType } from 'components/select';
import { SwitchBasic } from 'components/switch';
const { TextArea } = Input;

const ModalForm = ({ data = {}, visible, hideModal, fetchTable }) => {
    const [form] = Form.useForm(); // Initialize form instance

    const {
        values,
        loading,
        handleChangeSetValue,
        handleSubmit,
    } = useForm(data, fetchTable, hideModal);

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Jenis Ruangan"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Nama Jenis Ruangan *"
                            name="nama"
                            rules={[{ required: true, message: 'Nama harus diisi!' }]}>
                            <Input placeholder='Ex: Ruangan 1' />
                        </Form.Item>
                        <Form.Item
                            name="is_booking" >
                            <SwitchBasic
                                defaultChecked={values.is_booking === "1" ? true : false}
                                onChange={(val) => {
                                    handleChangeSetValue("is_booking", val ? "1" : "0");
                                }}
                            />
                            &nbsp;Aktifkan jika ruangan bisa di booking
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                            <Button onClick={hideModal} disabled={loading}>Batal</Button>
                            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ModalForm;