import { _tableLogActivity } from "lib/Helper";
import Action from "./Action";
import { SettingOutlined } from "@ant-design/icons";
import { DownloadFile } from "components/download";

const columns = (action, modalForm, modalDelete) => [
  {
    title: 'No',
    dataIndex: 'rownum',
    width: '3%',
  },
  {
    title: 'Tanggal',
    dataIndex: 'tgl',
  },
  {
    title: 'Kategori',
    dataIndex: 'kategori_tes',
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
  },
  {
    title: 'Dokumentasi',
    dataIndex: 'file',
    render: (_, row) => <DownloadFile file={row.dokumen} main_path={row.main_path} />

  },
  {
    title: <SettingOutlined />,
    key: 'operation',
    width: '2%',
    render: (a, row) => <Action row={row} action={action} modalForm={modalForm} modalDelete={modalDelete}/>,
  },
  {
    title: 'LogActivity',
    key: 'operation',
    width: '10%',
    render: (a, row) => _tableLogActivity(row),
  },
];

export default columns