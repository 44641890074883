import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getParamTable, getParamTempTableFiltering, _setAxios } from 'lib/Helper';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const initialTableState = () => ({
    loading_first: false,
    loading: false,
    data: [],
    search: "",
    pagination: {
        current: 1,
        pageSize: 10,
        total: null,
    },
    sorting: [],
    filtering: {
        tgl_awal: moment().format("YYYY-MM-DD"),
        tgl_akhir: moment().format("YYYY-MM-DD"),
    },
});

const useList = (tahunAjaran, semester) => {
    const { id, rombel_id } = useParams();

    const [table, setTable] = useState({
        ...initialTableState(),
        filtering: {
            ...initialTableState().filtering,
            tahun_ajaran_id: tahunAjaran?.id,
            semester_id: semester?.id
        },
    });
    const [detailData, setDetailData] = useState({});
    const [formState, setFormState] = useState({ data: {}, visible: false });
    const [filter, setFilterState] = useState({
        values: {
            ...table.filtering
        }, visible: false
    });
    const [deleteState, setDeleteState] = useState({ visible: false, data: {} });
    const [exportState, setExportState] = useState({ visible: false });

    const fetchTable = (isNew = false) => {
        const params = getParamTable("default", table);
        fetchData(params, isNew);
    };

    const fetchData = useCallback(async (params = {}, isNew = false) => {
        updateTableLoadingState(isNew);
        params.pagination.current = isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current;

        const resp = await _setAxios("bk/hasil-tes-siswa/table-riwayat/" + id, "POST", params);
        if (resp.status) {
            setTimeout(() => {
                updateTableData(resp.data, params, isNew);
            }, 0);
        } else {
            resetTableLoadingState();
        }
    }, [id]);

    const updateTableLoadingState = (isNew) => {
        setTable(prev => ({
            ...prev,
            loading_first: isMobile ? isNew : false,
            loading: true
        }));
    };

    const resetTableLoadingState = () => {
        setTable(prev => ({ ...prev, loading_first: false, loading: false }));
    };

    const updateTableData = (data, params, isNew) => {
        const updatedData = isNew ? [] : params.data;
        const current = params.pagination.current
        const newCurrent = isMobile ? current + 1 : current;

        setTable(prev => ({
            ...prev,
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...updatedData, ...data?.list] : data?.list,
            pagination: {
                ...prev.pagination,
                current: newCurrent,
                total: data.info.total,
            },
        }));
    };

    const setTempTableFilter = (name, value) => {
        setFilterState(prev => ({
            ...prev,
            values: { ...prev.values, [name]: value }
        }));
        setTable(prev => ({ ...prev, filtering: { ...prev.filtering, [name]: value } }));
    };

    const setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(table, filter.values);
        fetchData(params, isMobile);
        modalFilter(false);
    };

    const modalFilter = (visible = false) => {
        setFilterState(prev => ({ ...prev, visible }));
    };

    const modalForm = (visible = false, data = {}) => {
        setFormState({ visible, data });
    };

    const modalDelete = (visible = false, data = {}) => {
        setDeleteState({ visible, data });
    };

    const modalExport = (visible = false) => {
        setExportState({ visible });
    };

    const getHasilSiswaDetail = (id, rombel_id) => {
        if (!id || !rombel_id) {
            return []
        }
        return _setAxios("bk/hasil-tes-siswa/table-header/" + id + "/" + rombel_id, "GET", null).then(resp => {
            return resp
        })
    }

    useEffect(() => {
        getHasilSiswaDetail(id, rombel_id).then(resp => {
            let data = resp.data.data
            setDetailData(data)
        });
    }, [id, rombel_id]);

    return {
        table,
        formState,
        filter,
        deleteState,
        exportState,
        detailData,
        fetchTable,
        fetchData,
        setTempTableFilter,
        setTableFilterFromTemp,
        modalFilter,
        modalForm,
        modalDelete,
        modalExport,
    };
};

export default useList;
