// useForm.js
import { useState } from 'react';
import { _setAxios, _success } from 'lib/Helper';
import dayjs from 'dayjs';
import { dateFormat } from 'constant/constant_format';
import { useParams } from 'react-router-dom';

const dateNow = dayjs().format("YYYY-MM-DD");

const useForm = (data, fetchTable, hideModal, detailData) => {
    const { id, rombel_id } = useParams();
    const [values] = useState({
        ...data,
        tgl: data.tgl ? dayjs(data.tgl) : dayjs(dateNow),
    });
    const [loading, setLoading] = useState(false);

    const handleSubmit = (val) => {

        const params = {
            tgl: val.tgl ? val.tgl.format(dateFormat) : null,
            siswa_id: id,
            rombel_id: rombel_id,
            kategori_tes: val.kategori_tes || null,
            keterangan: val?.keterangan,
            dokumen: val?.file,
            tahun_ajaran_id: detailData.tahun_ajaran_id,
            semester_id: detailData.semester_id
        };

        let endpoint = "bk/hasil-tes-siswa";
        let method = "POST";

        if (data?.id) { 
            endpoint = `${endpoint}/${data?.id}`;
            method = "PUT";
        }

        setLoading(true);
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true);
                hideModal();
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        });
    };

    return {
        values,
        loading,
        handleSubmit,
    };
};

export default useForm;
