import React, { useState } from 'react';
import { connect } from 'react-redux'

import { Row, Col, Modal, Button, Form, Divider, Input, InputNumber } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

import { _success, _setAxios, _fmtRupiah, _isNumber, _getDate } from '../../../../lib/Helper';
import { SelectBiaya, SelectTingkatanKelas } from '../../../../components/select';
import SelectPaymentMethod from '../../../../components/select/SelectPaymentMethod';
import { UploadFile } from '../../../../components/upload-files';

import "./style.css"
import { Date } from 'components/datetime';
import { regexAddComma, regexRemoveComma } from 'utils/utils_regex';

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(customParseFormat);

const { TextArea } = Input;

const ModalForm = ({ privilege, data = {}, visible, hideModal, fetchTable, filterValues }) => {
    console.log("data-->>", data);
    const [form] = Form.useForm(); // Initialize form instance
    const [values, setValues] = useState({
        ...data,
        siswa: data.siswa_id,
        rombel: data.rombel_id,
        biaya: data.pemasukan_detail_id,
        tgl_transaksi: data?.tgl_transaksi ? dayjs(data?.tgl_transaksi) : dayjs(),
        metode_pembayaran: data.jenis_pembayaran,
        bukti_pembayaran: null,
    });
    const [loading, setLoading] = useState(false);


    const handleChangeSetValue = (name, value) => {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };

    const handleSubmit = async () => {
        setLoading({
            loading: true
        });

        const params = {
            siswa_id: values.siswa ? values.siswa : null,
            rombel_id: values.rombel ? values.rombel : null,
            pemasukan_detail_id: form.getFieldValue('biaya') || null,
            tingkatan_kelas: form.getFieldValue('tingkatan_kelas') || null,
            tahun_ajaran_id: filterValues?.tahun_ajaran_id || null,
            semester_id: filterValues?.semester_id || null,
            tgl_transaksi: _getDate(values.tgl_transaksi),
            jenis_pembayaran: form.getFieldValue('metode_pembayaran') || null,
            nominal_tagihan: form.getFieldValue('nominal_tagihan') ? _isNumber(form.getFieldValue('nominal_tagihan')) : null,
            nominal_pembayaran: form.getFieldValue('nominal_pembayaran') ? _isNumber(form.getFieldValue('nominal_pembayaran')) : null,
            bukti_pembayaran: values.bukti_pembayaran ? values.bukti_pembayaran : null,
            keterangan: form.getFieldValue('keterangan') || null
        }

        let endpoint = "keuangan/pemasukan/pembayaran"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                fetchTable(true)
                hideModal()
            }
            setLoading({
                loading: false
            });
        });
    };

    return (
        <Modal
            maskClosable={false}
            title={"Form Pembayaran Siswa"}
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null}
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                initialValues={values}
                onFinish={handleSubmit}
            >
                <Row gutter={[16, 0]}>
                    <Col xs={24}>
                        <Form.Item
                            label="Tanggal Bayar"
                            name={"tgl_transaksi"}
                            rules={[{ required: true, message: 'Tanggal Bayar harus diisi' }]}>
                            <Date onChange={(val) => { handleChangeSetValue("tgl_transaksi", val) }} />
                        </Form.Item>
                        {/* <Form.Item
                            label="Tingkatan Kelas"
                            name={"tingkatan_kelas"}
                            rules={[{ required: true, message: 'Tingkatan Kelas harus diisi' }]}>
                            <SelectTingkatanKelas
                                allowClear
                                value={values.tingkatan_kelas}
                                onChange={(val) => {
                                    handleChangeSetValue("tingkatan_kelas", val || null)
                                    form.setFieldsValue({ biaya: null })
                                    form.setFieldsValue({ nominal_tagihan: null })
                                }}
                            />
                        </Form.Item> */}
                        <Form.Item
                            label="Biaya"
                            name={"biaya"}
                            rules={[{ required: true, message: 'Biaya harus diisi' }]}>
                            <SelectBiaya
                                disabled={!values.siswa && !filterValues?.tahun_ajaran_id && !filterValues?.semester_id && !values.tingkatan_kelas}
                                tahunAjaranId={filterValues?.tahun_ajaran_id ? filterValues?.tahun_ajaran_id : ""}
                                tingkatanKelas={values.tingkatan_kelas ? values.tingkatan_kelas : ""}
                                value={values.biaya}
                                onChange={(val, row) => {
                                    form.setFieldsValue({ nominal_tagihan: row ? _fmtRupiah(row.nominal_tagihan) : null })
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label="Nominal Tagihan"
                            name={"nominal_tagihan"}
                            rules={[{ required: true, message: 'Nominal Tagihan harus diisi' }]}>
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled
                                controls={false}
                                addonBefore={"Rp."}
                                formatter={regexAddComma}
                                parser={regexRemoveComma}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label="Nominal Pembayaran"
                            name={"nominal_pembayaran"}
                            rules={[{ required: true, message: 'Nominal Pembayaran harus diisi' }]}>
                            <InputNumber
                                style={{ width: '100%' }}
                                controls={false}
                                addonBefore={"Rp."}
                                placeholder="0"
                                formatter={regexAddComma}
                                parser={regexRemoveComma}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label="Metode Pembayaran"
                            name={"metode_pembayaran"}
                            rules={[{ required: true, message: 'Metode Pembayaran harus diisi' }]}>
                            <SelectPaymentMethod
                                value={values.metode_pembayaran}
                            />
                        </Form.Item>
                        <Form.Item label="File">
                            <UploadFile
                                isValidation={true}
                                type="image"
                                onChange={(val) => {
                                    handleChangeSetValue("bukti_pembayaran", val ? val : null)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Keterangan"
                            name={"keterangan"}>
                            <TextArea
                                rows={4}
                                placeholder="Keterangan"
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} style={{ textAlign: "right" }}>
                        <Divider />
                        <Button onClick={hideModal}>Batal</Button> &nbsp;
                        <Button type="primary" htmlType="submit" loading={loading}>Simpan</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(ModalForm);
