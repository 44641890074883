import React, { useState } from 'react';
import { Modal, Button, Form, Divider } from 'antd';
import { _success, _setAxios } from '../../../lib/Helper';
import { SelectRombel, SelectSemester, SelectTahunAjaran } from 'components/select';
import { RangeDate } from 'components/datetime';
import dayjs from 'dayjs';
import { dateFormat } from 'constant/constant_format';

const periode = [dayjs().subtract(3, "month"), dayjs()]

const Export = ({ visible, hideModal, statusSiswa }) => {
    const [values, setValues] = useState({
        periode: periode,
    });
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleChangeSetValue = (name, value) => {
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleSubmit = async (formValues) => {
        setLoading(true);
        try {
            let params = {
                tahun_ajaran_id: formValues.tahun_ajaran_id || null,
                semester_id: formValues.semester_id || null,
                rombel_id: formValues.rombel_id || null,
                status_siswa: statusSiswa,
            }
            if (statusSiswa === "non-aktif") {
                params = {
                    tgl_awal: formValues.periode[0] ? formValues.periode[0].format(dateFormat) : null,
                    tgl_akhir: formValues.periode[1] ? formValues.periode[1].format(dateFormat) : null,
                    status_siswa: statusSiswa,
                }
            }

            const resp = await _setAxios("siswa/export/" + statusSiswa, "POST", params);

            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message);
                setTimeout(() => {
                    window.open(resp.data.data.link, '_blank');
                    setLoading(false);
                }, 1000);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setLoading(false);
        }
    };

    const handleFieldChange = (name, value, resetFields = []) => {
        handleChangeSetValue(name, value);
        resetFields.forEach(field => form.resetFields([field]));
    };

    return (
        <Modal
            title="Export data peserta didik"
            open={visible}
            onCancel={hideModal}
            footer={null}
        >
            <Form
                form={form}
                name="exportForm"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={values}
            >

                {statusSiswa === "non-aktif" ?
                    <Form.Item label="Periode" name="periode" rules={[{ required: true, message: 'Periode harus diisi' }]}>
                        <RangeDate
                            allowClear
                            defaultValue={periode}
                            format={dateFormat}
                        />
                    </Form.Item>
                    : <>
                        <Form.Item label="Tahun Ajaran" name="tahun_ajaran_id" rules={[{ required: true, message: 'Tahun Ajaran harus diisi' }]}>
                            <SelectTahunAjaran
                                allowClear
                                value={values.tahun_ajaran_id}
                                onChange={(val) => handleFieldChange("tahun_ajaran_id", val || null, ['semester_id', 'rombel_id'])}
                            />
                        </Form.Item>
                        <Form.Item label="Semester" name="semester_id" rules={[{ required: true, message: 'Semester harus diisi' }]}>
                            <SelectSemester
                                allowClear
                                value={values.semester_id}
                                tahunAjaranId={values.tahun_ajaran_id}
                                disabled={!values.tahun_ajaran_id}
                                onChange={(val) => handleFieldChange("semester_id", val || null, ['rombel_id'])}
                            />
                        </Form.Item>
                        <Form.Item label="Rombel" name="rombel_id" rules={[{ required: true, message: 'Rombel harus diisi' }]}>
                            <SelectRombel
                                allowClear
                                value={values.rombel_id}
                                tahunAjaranId={values.tahun_ajaran_id}
                                semesterId={values.semester_id}
                                disabled={!values.tahun_ajaran_id || !values.semester_id}
                                onChange={(val) => handleChangeSetValue("rombel_id", val || null)}
                            />
                        </Form.Item>
                    </>}

                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    <Button onClick={hideModal} disabled={loading}>Batal</Button>
                    <Button type="primary" htmlType="submit" loading={loading}>Lanjutkan</Button>
                </div>
            </Form>
        </Modal>
    );
};

export default Export;