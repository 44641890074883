// useForm.js
import { useEffect, useState } from 'react';

const useForm = (table, setTable, setValuesList, valuesList) => {
    const [isFirstSetValues, setIsFirstSetValues] = useState(true);
    const [headValues, setHeadValues] = useState({});

    const handleInputChange = (key, field, value) => {
      // Pastikan ada properti `list` dan `list` adalah array
      const list = Array.isArray(table.data) ? table.data : [];
  
      // Perbarui data di dalam `list`
      const updatedList = list.map((item) => {
        if(key === 0){
          setHeadValues({ ...headValues, [field]: value })
          return { ...item, [field]: value }
        }
        return item.id === key ? { ...item, [field]: value } : item
      });
  
      setTable({
        ...table,
        data: updatedList
      })

      if(isFirstSetValues){
        setValuesList(updatedList);
        setIsFirstSetValues(false);
        return
      }

      // mapping karena updatedList bisa jadi datanya lebih sidikit dari values
      const updatedValues = valuesList.map((item) => {
        return updatedList.find((row) => row.id === item.id) || item
      });

      setValuesList(updatedValues);

    };

    useEffect(() => {
      setValuesList(table.data);
    }, [table.data]);
  
    return {
        headValues,
        handleInputChange,
    };
};

export default useForm;
