import React from 'react';
import { Modal, Button } from 'antd';
import { _setAxios, _success } from '../../../../lib/Helper';

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                kategoriAssetLancar: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = () => {
        const { values } = this.state
        this.setState({
            loading: true
        });
        _setAxios("sarpras/sarana-pembelajaran/export", "POST", {
            kategori_id: values.kategori_id ? values.kategori_id : null
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getKategoriAssetLancarDDL = () => {
        _setAxios("sarpras/kategori", "POST", {
            "jenis": "Asset Lancar"
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        kategoriAssetLancar: resp.data.data
                    }
                });
            }
        })
    }

    componentDidMount() {
        this.getKategoriAssetLancarDDL()
    }

    render() {

        return (
            <Modal
                title="Export"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
               Apakah Anda yakin, data akan di export?
            </Modal>
        )
    }
}

export default Export;
