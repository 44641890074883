import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, BackTop, Button, Breadcrumb } from 'antd';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import { TableList } from 'components/list';
import AuthRedirect from 'components/AuthRedirect';
import ModalForm from '../modal/Form';
import ModalFilter from '../modal/Filter';
import ModalDelete from '../modal/Delete';
import SearchTable from 'components/search-table/SearchTable';
import { PageHeader } from 'components/header';
import { SortingTableOnly } from 'components/sorting-table';
import columns from './Columns';
import useList from '../hooks/useList';
import { useHistory } from "react-router-dom";
import Header from './Header';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}];

const HasilTesPesertaDidikDetail = ({ privilege }) => {
  const history = useHistory();

  const {
    table,
    formState,
    filter,
    deleteState,
    detailData,
    fetchTable,
    fetchData,
    setTempTableFilter,
    setTableFilterFromTemp,
    modalFilter,
    modalForm,
    modalDelete,
  } = useList(privilege?.tahun_ajaran, privilege?.semester);

  const access = privilege.access["/bimbingan-konseling/hasil-tes"];
  if (!access) return <AuthRedirect />;
  const action = access.action;

  return (
    <>
      <PageHeader
        style={{
          padding: 0
        }}
        onBack={() => window.history.back()}
        title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
          <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => history.push("/bimbingan-konseling/hasil-tes")}>Hasil Tes Peserta Didik</Breadcrumb.Item>
          <Breadcrumb.Item>Detail </Breadcrumb.Item>
        </Breadcrumb>}
      />

      <Header detailData={detailData} />

      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <PageHeader
          className="site-page-header"
          title="Hasil Tes Peserta Didik - Riwayat Data"
          extra={[
            action.create && (
              <Button
                type='primary'
                onClick={() => modalForm(true)}
                icon={<PlusOutlined />}>
                Tambah
              </Button>
            ),
          ]} />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={18} md={19}>
            <SearchTable table={table} fetch={fetchData} />
          </Col>
          <Col xs={24} sm={6} md={5}>
            <SortingTableOnly
              table={table}
              fetch={fetchData}
              sortFields={sortFields}
            />
          </Col>
          <Col xs={24}>
            <TableList
              state={{ table, filter }}
              fetch={fetchData}
              fetchTable={fetchTable}
              columns={columns(action, modalForm, modalDelete)}
            />
          </Col>
        </Row>
      </div>

      {formState.visible && (
        <ModalForm
          detailData={detailData}
          data={formState.data}
          visible={formState.visible}
          hideModal={() => modalForm(false)}
          fetchTable={fetchTable}
        />
      )}
      {filter.visible && (
        <ModalFilter
          values={filter.values}
          visible={filter.visible}
          hideModal={() => modalFilter(false)}
          setTempTableFilter={setTempTableFilter}
          setTableFilterFromTemp={setTableFilterFromTemp}
        />
      )}
      {deleteState.visible && (
        <ModalDelete
          data={deleteState.data}
          visible={deleteState.visible}
          hideModal={() => modalDelete(false)}
          fetchTable={fetchTable}
        />
      )}
      <BackTop />
    </>

  );
};

const mapStateToProps = (state) => ({
  privilege: state.privilege,
});

export default connect(mapStateToProps)(HasilTesPesertaDidikDetail);