import React, { useEffect, useState } from 'react';
import { List, Skeleton, Divider } from 'antd';

const Header = ({detailData}) => {

    const [loading, setLoading] = useState(true);
    const [header, setHeader] = useState();

    useEffect(() => {
        if(detailData){
            setHeader([
                {
                    title: "NISN",
                    description: detailData.nisn ? detailData.nisn : "-",
                },
                {
                    title: "Nama Peserta Didik",
                    description: detailData.nama ? detailData.nama : "-",
                },
                {
                    title: "Jenis Kelamin",
                    description: detailData.jenis_kelamin ? detailData.jenis_kelamin : "-",
                },
                {
                    title: "Rombel",
                    description: detailData.rombel_nama ? detailData.rombel_nama : "-",
                },
            ])
            //set delay
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }

    }, [detailData]);

    return (
        <>
            <div className="site-layout-background">
                {
                    !loading ?
                        <List
                            itemLayout="horizontal"
                            dataSource={header}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                    />
                                </List.Item>
                            )}
                        />
                        :
                        <Skeleton active />
                }
            </div>

            <Divider />
        </>
    );
};
export default Header;
