import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, _success, getParamTable } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'

import { Table, Row, Col, Divider, Popover, Button, Radio, Space, Input, Tooltip, Breadcrumb, List, Form, Modal, } from 'antd';
import { PageHeader } from 'components/header';
import { DownloadOutlined, InfoCircleOutlined, SearchOutlined, SortAscendingOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { SelectRombel, SelectSemester, SelectTahunAjaran } from 'components/select';
import { RangeDate } from 'components/datetime';
import { dateFormat } from 'constant/constant_format';
import moment from 'moment';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "siswa_nama",
        label: "Nama Peserta Didik",
    }]
}

class LaporanDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [{
                    field: "siswa_nama",
                    sort: "ASC",
                    urutan: 1
                }],
                filtering: {
                    tipe: null,
                    tgl_awal: null,
                    tgl_akhir: null,
                    rombel_id: this.props.match.params.rombel_id
                },
            },
            dataDetail: {
                data: {},
                data1: [
                    {
                        title: "Nama Rombel",
                        description: "-",
                    },
                ],
            },
            exportState: {
                loading: false,
                visible: false,
                values: {
                    tahun_ajaran_id: null,
                    semester_id: null,
                    rombel_id: null,
                    periode: null
                },
            },
        };
    }

    componentDidMount() {
        this.getDetail()
        this.fetchTable()
    }

    getDetail = () => {
        _setAxios("rombel/" + this.props.match.params.rombel_id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    dataDetail: {
                        data: data,
                        data1: [
                            {
                                title: "Nama Rombel",
                                description: data.nama ? data.nama : "-",
                            }
                        ],
                    }
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        const query = new URLSearchParams(this.props.location.search);
        this.setState({
            table: {
                ...params,
                loading: true,
                filtering: {
                    ...this.state.table.filtering,
                    tipe: query.get('tipe') === "undefined" ? null : query.get('tipe'),
                    // tgl_awal: query.get('tgl_awal') === "undefined" ? null : query.get('tgl_awal'),
                    // tgl_akhir: query.get('tgl_akhir') === "undefined" ? null : query.get('tgl_akhir'),
                }
            }
        });

        params = {
            ...params,
            filtering: {
                ...params.filtering,
                tipe: query.get('tipe') === "undefined" ? null : query.get('tipe'),
                // tgl_awal: query.get('tgl_awal') === "undefined" ? null : query.get('tgl_awal'),
                // tgl_akhir: query.get('tgl_akhir') === "undefined" ? null : query.get('tgl_akhir'),
            }
        }

        _setAxios("konsultasi/table-laporan-by-rombel", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        this.setState({
            values: values,
        });
    }

    handleChangeSetValueExport = (name, value) => {
        const { values } = this.state.exportState;
        this.setFormExport(values, name, value)
    };

    setFormExport = (values, name, value) => {
        values[name] = value;

        this.setState({
            exportState: {
                ...this.state.exportState,
                values: values
            }
        })
    }

    hideModalExport = () => {
        this.setState({
            exportState: {
                ...this.state.exportState,
                visible: false,
                loading: false
            },
        });
    };

    showModalExport = () => {
        this.setState({
            exportState: {
                ...this.state.exportState,
                visible: true
            },
        });
    };

    onExport = () => {
        const { exportState } = this.state
        const query = new URLSearchParams(this.props.location.search);
        _setAxios("konsultasi/export-laporan-by-rombel", "POST", {
            tipe: query.get('tipe') === "undefined" ? null : query.get('tipe'),
            tahun_ajaran_id: exportState.values?.tahun_ajaran_id,
            semester_id: exportState.values?.semester_id,
            rombel_id: exportState.values?.rombel_id,
            tgl_awal: exportState.values?.tgl_awal,
            tgl_akhir: exportState.values?.tgl_akhir
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.open(resp.data?.data?.link, '_blank');
                }, 1000);
            }
        })
    }

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/laporan"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined || action.detail === false) {
            return <AuthRedirect />
        }

        const { table, dataDetail, exportState } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalExport = <Modal
            title="Export Laporan Koseling Detail"
            open={this.state.exportState.visible}
            onCancel={this.hideModalExport}
            footer={<>
                <Button onClick={this.hideModalExport}>Tutup</Button>
                <Button type='primary' onClick={this.onExport} loading={this.state.exportState.loading}>Export</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tahun Ajaran" rules={[{ required: true, message: 'Tahun Ajaran harus diisi' }]}>
                    <SelectTahunAjaran
                        allowClear
                        value={exportState?.values.tahun_ajaran_id}
                        rules={[{ required: true, message: 'Tahun Ajaran harus diisi' }]}
                        onChange={(val) => {
                            this.handleChangeSetValueExport("tahun_ajaran_id", val || null);
                            this.handleChangeSetValueExport("semester_id", null);
                            this.handleChangeSetValueExport("rombel_id", null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Semester" rules={[{ required: true, message: 'Semester harus diisi' }]}>
                    <SelectSemester
                        allowClear
                        value={exportState?.values.semester_id}
                        tahunAjaranId={exportState?.values.tahun_ajaran_id}
                        disabled={!exportState?.values.tahun_ajaran_id ? true : false}
                        onChange={(val) => {
                            this.handleChangeSetValueExport("semester_id", val || null);
                            this.handleChangeSetValueExport("rombel_id", null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Rombel" rules={[{ required: true, message: 'Rombel harus diisi' }]}>
                    <SelectRombel
                        allowClear
                        value={exportState?.values.rombel_id}
                        tahunAjaranId={exportState?.values.tahun_ajaran_id}
                        semesterId={exportState?.values.semester_id}
                        disabled={!exportState?.values.tahun_ajaran_id || !exportState?.values.semester_id ? true : false}
                        onChange={(val) => {
                            this.handleChangeSetValueExport("rombel_id", val || null);
                        }}
                    />
                </Form.Item>
                <Form.Item label="Periode">
                    <RangeDate
                        defaultValue={exportState.values?.periode}
                        format={dateFormat}
                        onChange={val => {
                            if (val) {
                                let periodeAwal = moment(val[0]).format(dateFormat)
                                let periodeAkhir = moment(val[1]).format(dateFormat)
                                this.handleChangeSetValueExport("tgl_awal", periodeAwal);
                                this.handleChangeSetValueExport("tgl_akhir", periodeAkhir);
                            }
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/bimbingan-konseling/laporan`}>Laporan Konseling</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={[
                        // action.export ? <Button onClick={this.onExport}><DownloadOutlined /> Export</Button> : null
                        action.export ? <Button onClick={this.showModalExport}><DownloadOutlined /> Export</Button> : null
                    ]}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Laporan Konseling Detail"
                        subTitle="Data laporan konseling detail"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={18}>
                            <List
                                itemLayout="horizontal"
                                dataSource={dataDetail.data1}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col xs={24} sm={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }}
                                value={table.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={3}>
                            <Button.Group className='ant-btn-group-fullwidth'>
                                <Popover placement="bottom" content={sortComponent} trigger="click">
                                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                                </Popover>
                            </Button.Group>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'Nama Peserta Didik',
                                        dataIndex: 'siswa_nama',
                                    },
                                    {
                                        title: 'Pribadi',
                                        dataIndex: 'pribadi',
                                    },
                                    {
                                        title: 'Sosial',
                                        dataIndex: 'sosial',
                                    },
                                    {
                                        title: 'Belajar',
                                        dataIndex: 'belajar',
                                    },
                                    {
                                        title: 'Karir',
                                        dataIndex: 'karir',
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
                {modalExport}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(LaporanDetail);
