import React from 'react';
import { Modal, Button, Divider, Form, Input } from 'antd';
import useForm from '../hooks/useForm';
import { SelectKategoriHasilTes } from 'components/select';
import { Date } from 'components/datetime';
import { UploadFile } from 'components/upload-files';
const { TextArea } = Input;

const ModalForm = ({ data = {}, visible, hideModal, fetchTable, detailData }) => {
    const [form] = Form.useForm(); // Initialize form instance

    const {
        values,
        loading,
        handleSubmit,
    } = useForm(data, fetchTable, hideModal, detailData);

    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            title="Form Hasil Test"
            open={visible}
            onCancel={hideModal}
            closable={false}
            footer={null} // Remove footer from Modal
        >
            <Form
                form={form}
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={handleSubmit}
                loading={loading}
                initialValues={values}
            >
                <Form.Item
                    label="Tanggal"
                    name="tgl"
                    rules={[{ required: true, message: 'Tanggal harus diisi!' }]}>
                    <Date />
                </Form.Item>
                <Form.Item
                    label="Kategori"
                    name="kategori_tes"
                    rules={[{ required: true, message: 'Kategori harus dipilih!' }]}>
                    <SelectKategoriHasilTes />
                </Form.Item>
                <Form.Item
                    label="Keterangan"
                    name="keterangan"
                    rules={[{ required: true, message: 'Keterangan harus diisi!' }]}
                    >
                    <TextArea placeholder='Keterangan' />
                </Form.Item>
                <Form.Item label="File" name="file" rules={[{ required: data.id ? false : true, message: 'File harus diisi!' }]}>
                    <UploadFile
                        isValidation={true}
                        type="jpg, png & pdf"
                    />
                </Form.Item>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    <Button onClick={hideModal} disabled={loading}>Batal</Button>
                    <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Simpan</Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ModalForm;